

import PhotoList from '@/components/photosGoods/PhotoList.vue'
import PhotoListSkeleton from '@/components/photosGoods/PhotoListSkeleton.vue'
import { defineComponent } from 'vue'
import { db } from '@/main'

export default defineComponent({
  name: 'GameProfile',
  components: {
    PhotoList,
    PhotoListSkeleton
  },
  data () {
    return {
      category: {},
      arrayImages: []
    }
  },
  created () {
    const dbRef = db.collection('good').doc(this.$route.params.id)
    dbRef.get().then((doc) => {
      this.category = doc.data()
      this.arrayImages = this.category.arrayImages
    }).catch((error) => {
      console.log(error)
    })
  },
  methods: {
    onUpdateForm (event) {
      event.preventDefault()
      db.collection('good').doc(this.$route.params.id)
        .update(this.photo).then(() => {
          console.log('category successfully updated!')
          this.$router.push('/list')
        }).catch((error) => {
          console.log(error)
        })
    }
  }
})
